<template>
  <div class="container-xs" style="display: grid; gap: 2rem">
    <AppBackHeader @back="$refs.exitPassenger.show()"
      >TRAVEL COMPANION</AppBackHeader
    >
    <AppDataExclaimer />
    <TravelerForm
      ref="form"
      @valid="value => (isValid = value)"
      @create-start="onCreateStart"
      @create-success="onCreateSuccess"
      @create-error="onCreateError"
      @create-end="onCreateEnd"
    />
    <XButton
      type="large"
      :disabled="!isValid"
      @click="$refs.form.createTraveler()"
      >SAVE</XButton
    >
    <InfoModal
      ref="infoModal"
      title="Error"
      text="Something went wrong"
      cta="try again"
      @cta="$refs.infoModal.hide()"
    />
    <LoadingModalFullScreen
      ref="loadingModal"
      text="Please wait"
      non-closable
    />
    <InfoModal
      ref="exitPassenger"
      title="ARE YOU SURE YOU WANT TO EXIT?"
      text="Your changes to travel companion will not be saved."
      hide-close-button
      cta="EXIT"
      alt-cta="CANCEL"
      @cta="$router.push('/search/summary')"
      @alt-cta="$refs.exitPassenger.hide()"
    />
  </div>
</template>

<script>
import AppBackHeader from '@/components-rf/AppBackHeader.vue'
import TravelerForm from '@/components-rf/forms/TravelerForm.vue'
import LoadingModalFullScreen from '@/components-rf/modals/LoadingModalFullScreen.vue'
import InfoModal from '@/components-rf/modals/InfoModal.vue'
import AppDataExclaimer from '../../../components-rf/AppDataExclaimer.vue'

export default {
  name: 'TripTravelersCreate',
  components: {
    AppBackHeader,
    TravelerForm,
    LoadingModalFullScreen,
    InfoModal,
    AppDataExclaimer,
  },
  data() {
    return {
      isValid: false,
    }
  },
  methods: {
    onCreateStart() {
      this.$refs.loadingModal.show()
    },
    onCreateSuccess(traveler) {
      this.$store.commit('SearchState/selectTraveler', traveler.id)
      this.$router.push('/search/summary')
    },
    onCreateError() {
      this.$refs.infoModal.show()
    },
    onCreateEnd() {
      this.$refs.loadingModal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
